import moment, {Moment} from 'moment-timezone';

export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const ISO_FORMAT_DATE = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm' as const;
export const CLEAR_FORMAT = 'DD.MM.YYYY, HH:mm';
export const DATE_FORMAT = 'DD.MM.YYYY';

export const convertDateToString = (
  date: string | Moment,
  outPutFormat = CLEAR_FORMAT,
  inputFormat = ISO_FORMAT,
) => {
  if (!date) return '';
  return moment(date, inputFormat).format(outPutFormat);
};

export const convertDateToStringWithTimeZone = (
  date: string | Moment,
  outPutFormat = CLEAR_FORMAT,
  inputFormat = ISO_FORMAT,
) => {
  if (!date) return '';
  return moment.utc(date, inputFormat).tz(moment.tz.guess()).format(outPutFormat);
};

export const convertDateToUnix = (date: string | Moment, inputFormat = ISO_FORMAT) => {
  if (!date) return moment().unix();
  return moment(date, inputFormat).unix();
};

export const joinDateAndTimeToISOString = (date: string, time: string) => {
  const newDate = convertDateToString(date, CLEAR_FORMAT, ISO_FORMAT_DATE);
  const newFullDate = `${newDate.split(',')[0]}, ${time}`;
  return moment(newFullDate, CLEAR_FORMAT).format(ISO_FORMAT);
};
