import React, {useState} from 'react';
import {connect} from 'react-redux';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {useChangeStatusMutation, useGetStatusQuery} from '@store/application/api';
import {GlobalState} from '@store/reducer';
import {selectClientId, selectIsLastStatus} from '@store/application/selectors';

type Error = {
  status?: number;
  data?: {
    message?: string;
  };
};

interface StateProps {
  clientId: string;
  isLastStatus: boolean;
}

interface ApplicationStatusProps {
  statusText: string;
}

type Props = ApplicationStatusProps & StateProps;

const ApplicationStatus = ({statusText, clientId, isLastStatus}: Props) => {
  const [changeStatus, {isLoading}] = useChangeStatusMutation();
  const {isError} = useGetStatusQuery(clientId, {pollingInterval: 30000});

  const [message, setMessage] = useState('');

  const onChangeStatus = () => {
    setMessage('');
    void (async () => {
      await changeStatus({status: 'success', client_id: clientId}).then(res => {
        if ('error' in res) {
          const error = res.error as Error;
          if ('status' in error && error.status === 409 && 'data' in error && error.data?.message) {
            setMessage(error.data.message);
            return;
          }
          setMessage('Ошибка при изменении статуса');
        }
      });
    })();
  };

  return (
    <>
      <Input
        value={statusText}
        disabled={true}
        hint="Статус"
        isTextarea={true}
        classNameHint="user-info__hint"
        className="user-info__textarea"
      />
      <Button
        onClick={onChangeStatus}
        text="Перевести в следующий статус"
        className="user-info__button"
        isLoading={isLoading}
        disabled={isLastStatus}
      />
      {message && <div className="text__error">{message}</div>}
      {isError && <div className="text__error">Ошибка при изменении статуса в фоном режиме</div>}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  clientId: selectClientId(state),
  isLastStatus: selectIsLastStatus(state),
});

export default connect(mapStateToProps)(ApplicationStatus);
