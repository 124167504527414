import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQueryWithLogout} from '@store/api';
import {ClientUpdate, GetUserResponse} from '@store/application/api';

export const clientApi = createApi({
  baseQuery: baseQueryWithLogout,
  reducerPath: 'clientApi',
  keepUnusedDataFor: 5,
  endpoints: builder => ({
    getClient: builder.query<GetUserResponse, string>({
      query: id => ({
        url: 'clients',
        method: 'GET',
        params: {
          client_id: id,
        },
      }),
    }),
    updateClient: builder.mutation<ClientUpdate, {clientId: string; requestData: ClientUpdate}>({
      query: ({clientId, requestData}) => ({
        url: 'clients',
        method: 'PATCH',
        params: {
          client_id: clientId,
        },
        body: requestData,
      }),
    }),
  }),
});

export const {useLazyGetClientQuery, useUpdateClientMutation} = clientApi;
