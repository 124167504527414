import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {useAuthorizationMutation} from '@store/auth/api';

import {ONE_DAY_IN_MILLISECONDS} from '@constants/common';

import {setCookie} from '@utils/cookieApi';

import './index.css';

const Auth = () => {
  const [logInProfile, {isLoading}] = useAuthorizationMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [infoMessage, setInfoMessage] = useState('');

  const fromPage = (location.state as {from?: string})?.from || '/application';

  const onChangeLogin = (text: string) => {
    setLogin(text);
    setInfoMessage('');
  };
  const onChangePassword = (text: string) => {
    setPassword(text);
    setInfoMessage('');
  };

  const logIn = () => {
    void (async () => {
      const loginData = {
        login: login?.trim(),
        password: password?.trim(),
      };
      await logInProfile(loginData)
        .then(response => {
          if (
            'error' in response &&
            response.error &&
            'originalStatus' in response.error &&
            response.error.originalStatus === 401
          ) {
            setInfoMessage('Неверный логин или пароль');
            return;
          }

          if ('data' in response && response.data && 'access_token' in response.data) {
            setCookie('token', response.data.access_token, {
              path: '/',
              maxAge: ONE_DAY_IN_MILLISECONDS,
            });
            navigate(fromPage, {replace: true});
            return;
          }

          //Если произошла непредвиденная ошибка, а код в блоке then
          setDisabled(true);
          setInfoMessage('Произошла ошибка, попробуйте перезагрузить страницу');
        })
        .catch(err => {
          console.log(err);
          setDisabled(true);
          setInfoMessage('Произошла ошибка, попробуйте перезагрузить страницу');
        });
    })();
  };

  useEffect(() => {
    if (login.length > 3 && password.length > 3) {
      setDisabled(false);
      return;
    }

    setDisabled(true);
  }, [login, password]);

  return (
    <form className="auth__form">
      <div className="auth__title mb-3">Авторизация в админ панели</div>
      <Input hint="Логин" value={login} onChange={onChangeLogin} className="mb-3" />
      <Input
        hint="Пароль"
        possibleHide
        value={password}
        onChange={onChangePassword}
        className="mb-3"
      />
      <Button
        className="auth__button mb-2"
        disabled={disabled}
        isLoading={isLoading}
        text={'Войти'}
        onClick={logIn}
      />
      {infoMessage && <div className="text__error">{infoMessage}</div>}
    </form>
  );
};

export default Auth;
