import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQueryWithLogout} from '@store/api';

export interface GetFilesResponse {
  name: string;
  date: string;
  owner: 'client' | 'support';
  url: string;
  size: number;
}

type GetFileParams = {
  file_name: string;
  bucket: string;
};

export const clientStorageApi = createApi({
  baseQuery: baseQueryWithLogout,
  reducerPath: 'clientStorageApi',
  keepUnusedDataFor: 5,
  endpoints: builder => ({
    getFiles: builder.query<GetFilesResponse[], string>({
      query: id => ({
        url: 'clients/files',
        method: 'GET',
        params: {client_id: id},
      }),
    }),
    getFile: builder.query<string, GetFileParams>({
      query: params => ({
        url: 'clients/file',
        method: 'GET',
        //Раскоментировать если ответ будет файл, а не ссылка
        // responseHandler: response => response.blob(),
        params,
      }),
    }),
    sendFile: builder.mutation<GetFilesResponse[], FormData>({
      query: fileData => ({
        url: 'clients/files',
        method: 'POST',
        body: fileData,
      }),
    }),
  }),
});

export const {useLazyGetFilesQuery, useLazyGetFileQuery, useSendFileMutation} = clientStorageApi;
