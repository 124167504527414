import React from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';

import Navigation from '@components/Navigation';

import {getCookie} from '@utils/cookieApi';

const PrivateRoutes = () => {
  const location = useLocation();
  const token = getCookie('token') as string | undefined;

  return token ? (
    <>
      <Navigation />
      <Outlet />
    </>
  ) : (
    <Navigate to="/auth" state={{from: location?.pathname}} />
  );
};

export default PrivateRoutes;
