import React from 'react';

import Button from '@components/Common/Button';

import './index.css';

interface UserInfoPhotosProps {
  photos: string[];
}

type Props = UserInfoPhotosProps;

const UserInfoPhotos = ({photos}: Props) => {
  const downLoadPhoto = (url: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  if (!photos) return null;

  return (
    <div className="photo__container mb-3">
      {photos.map((photo, index) => (
        <Button
          key={index}
          onClick={() => downLoadPhoto(photo)}
          text={`Фото паспорта ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default UserInfoPhotos;
