import {combineReducers} from '@reduxjs/toolkit';

import {State as Auth} from '@store/auth/slice';
import {State as Client} from '@store/client/slice';
import {State as Application} from '@store/application/slice';

import authSlice from '@store/auth/slice';
import clientSlice from '@store/client/slice';
import applicationSlice from '@store/application/slice';

import {authApi} from '@store/auth/api';
import {clientApi} from '@store/client/api';
import {applicationApi} from '@store/application/api';
import {clientStorageApi} from '@store/client/apiStorage';

export interface GlobalState {
  auth: Auth;
  application: Application;
  client: Client;
}

export const reducers = combineReducers({
  auth: authSlice.reducer,
  application: applicationSlice.reducer,
  client: clientSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [applicationApi.reducerPath]: applicationApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [clientStorageApi.reducerPath]: clientStorageApi.reducer,
});
