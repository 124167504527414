import React from 'react';
import {Link} from 'react-router-dom';

import {FileItem} from '@store/client/types';

import {SvgFile} from '@assets/icons';

import './index.css';

interface FileItemProps {
  fileObject: FileItem;
}

type Props = FileItemProps;

const FilesListItem = ({fileObject: {owner, name, size, url, date}}: Props) => {
  return (
    <Link className="file-item" to={url} target="_blank">
      <div className="file-item__icon">
        <SvgFile size={36} />
      </div>
      <div className="file-item__info">
        <div className="file-item__name">{name}</div>
        <div className="file-item__sender">{`От ${owner === 'support' ? 'поддержки' : 'клиента'} в ${date}, ${size}`}</div>
      </div>
    </Link>
  );
};

export default FilesListItem;
