import React from 'react';

import FilesListItem from '@components/Client/Client/Files/List/Row/Item';

import {ClientFile as ClientFileProps} from '@store/client/types';

import './index.css';

type Props = ClientFileProps;

const FilesListRow = ({files, date}: Props) => {
  if (!files?.length) return null;
  return (
    <div className="files__list-row">
      <div className="files__list-row__date">{date}</div>
      <div className="files__list-row__files">
        {files
          .sort((a, b) => b.date.localeCompare(a.date))
          .map(file => (
            <FilesListItem key={file.url} fileObject={file} />
          ))}
      </div>
    </div>
  );
};

export default FilesListRow;
