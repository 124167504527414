import React, {useEffect, useMemo, useState} from 'react';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {User} from '@store/application/slice';
import {ClientUpdate, useUpdateUserMutation} from '@store/application/api';
import {useUpdateClientMutation} from '@store/client/api';

import {USER_PAIR} from '@constants/common';

import {MASKS, validate, VALIDATION_TYPE} from '@utils/validations';

import './index.css';

export type ActionButtonType = 'client' | 'user';

interface EditableFormProps {
  client: User;
  actionButtonType: ActionButtonType;
}

type Props = EditableFormProps;

const EditableForm = ({
  client: {
    id: clientId,
    phoneNumber,
    address,
    companyName,
    firstName,
    lastName,
    middleName,
    email,
    accountNumber,
    mailingAddress,
    employeesCount,
    inn,
    localPaymentNumber,
  },
  actionButtonType,
}: Props) => {
  const [updateUser, {isLoading: isLoadingUser, isError: isErrorUser}] = useUpdateUserMutation();
  const [updateClient, {isLoading: isLoadingClient, isError: isErrorClient}] =
    useUpdateClientMutation();

  const [disabled, setDisabled] = useState(false);

  const [currentPhoneNumber, setPhoneNumber] = useState(phoneNumber);
  const [currentAddress, setAddress] = useState(address);
  const [currentCompanyName, setCompanyName] = useState(companyName);
  const [currentFirstName, setFirstName] = useState(firstName);
  const [currentLastName, setLastName] = useState(lastName);
  const [currentMiddleName, setMiddleName] = useState(middleName);
  const [currentEmail, setEmail] = useState(email);
  const [currentAccountNumber, setAccountNumber] = useState(accountNumber);
  const [currentMailingAddress, setMailingAddress] = useState(mailingAddress);
  const [currentEmployeesCount, setEmployeesCount] = useState(employeesCount);
  const [currentInn, setInn] = useState(inn);
  const [currentLocalPaymentNumber, setCurrentLocalPaymentNumber] = useState(localPaymentNumber);

  const onChangePhoneNumber = (text: string): void => setPhoneNumber(text);
  const onChangeAddress = (text: string): void => setAddress(text);
  const onChangeCompanyName = (text: string): void => setCompanyName(text);
  const onChangeFirstName = (text: string): void => setFirstName(text);
  const onChangeLastName = (text: string): void => setLastName(text);
  const onChangeMiddleName = (text: string): void => setMiddleName(text);
  const onChangeEmail = (text: string): void => setEmail(text);
  const onChangeAccountNumber = (text: string): void => setAccountNumber(text);
  const onChangeMailingAddress = (text: string): void => setMailingAddress(text);
  const onChangeCurrentLocalPaymentNumber = (text: string): void =>
    setCurrentLocalPaymentNumber(parseInt(text) ? parseInt(text) : 1);
  const onChangeInn = (text: string): void => (text.length <= 12 ? setInn(text) : undefined);

  const onChangeEmployeesCount = (text: string): void => {
    setEmployeesCount(parseInt(text) ? parseInt(text) : 0);
  };

  useEffect(() => {
    if (
      (validate(currentEmployeesCount.toString(), VALIDATION_TYPE.NUMBER) &&
        phoneNumber === currentPhoneNumber &&
        address === currentAddress &&
        companyName === currentCompanyName &&
        firstName === currentFirstName &&
        lastName === currentLastName &&
        middleName === currentMiddleName &&
        localPaymentNumber === currentLocalPaymentNumber &&
        email === currentEmail &&
        accountNumber === currentAccountNumber &&
        mailingAddress === currentMailingAddress &&
        inn === currentInn &&
        employeesCount === currentEmployeesCount) ||
      !validate(currentInn, VALIDATION_TYPE.INN)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [
    accountNumber,
    address,
    companyName,
    currentAccountNumber,
    currentAddress,
    currentCompanyName,
    currentEmail,
    currentEmployeesCount,
    currentFirstName,
    currentLastName,
    currentMailingAddress,
    currentMiddleName,
    currentPhoneNumber,
    currentInn,
    email,
    employeesCount,
    firstName,
    lastName,
    mailingAddress,
    middleName,
    phoneNumber,
    inn,
    localPaymentNumber,
    currentLocalPaymentNumber,
  ]);

  const onSave = () => {
    const requestData: ClientUpdate = {
      phone_number: currentPhoneNumber,
      address: currentAddress,
      legal_entity_name: currentCompanyName,
      name: currentFirstName,
      last_name: currentLastName,
      middle_name: currentMiddleName,
      email: currentEmail,
      account: currentAccountNumber,
      mailing_address: currentMailingAddress,
      employees_count: currentEmployeesCount,
      inn: currentInn,
      local_payment_number: currentLocalPaymentNumber,
    };

    if (actionButtonType === 'user') {
      void (async () => {
        await updateUser({clientId, requestData}).then(res => {
          if ('data' in res && res.data) alert('Данные успешно обновлены');
        });
      })();
    }
    if (actionButtonType === 'client') {
      void (async () => {
        await updateClient({clientId, requestData}).then(res => {
          if ('data' in res && res.data) alert('Данные успешно обновлены');
        });
      })();
    }
  };

  const CLIENT_INPUTS = useMemo(() => {
    return [
      {
        value: currentPhoneNumber,
        onChange: onChangePhoneNumber,
        hint: USER_PAIR['phoneNumber'],
        placeholder: '+7 (111) 111-11-11',
        mask: MASKS[VALIDATION_TYPE.PHONE],
      },
      {
        value: currentAddress,
        onChange: onChangeAddress,
        hint: USER_PAIR['address'],
        placeholder: '111111, Город, ул. Улица, дом 1, кв 1',
      },
      {
        value: currentCompanyName,
        onChange: onChangeCompanyName,
        hint: USER_PAIR['companyName'],
        placeholder: 'ИП Иванов Иван Иванович',
      },
      {
        value: currentFirstName,
        onChange: onChangeFirstName,
        hint: USER_PAIR['firstName'],
        placeholder: 'Иван',
      },
      {
        value: currentLastName,
        onChange: onChangeLastName,
        hint: USER_PAIR['lastName'],
        placeholder: 'Иванов',
      },
      {
        value: currentMiddleName,
        onChange: onChangeMiddleName,
        hint: USER_PAIR['middleName'],
        placeholder: 'Иванов',
      },
      {
        value: currentEmail,
        onChange: onChangeEmail,
        hint: USER_PAIR['email'],
        placeholder: 'test@mail.ru',
      },
      {
        value: currentAccountNumber,
        onChange: onChangeAccountNumber,
        hint: USER_PAIR['accountNumber'],
        placeholder: '00000 000 0 000000 00000',
        mask: MASKS[VALIDATION_TYPE.BANK_ACCOUNT],
      },
      {
        value: currentInn,
        onChange: onChangeInn,
        hint: USER_PAIR['inn'],
        placeholder: '000000000000',
      },
      {
        value: currentLocalPaymentNumber.toString(),
        onChange: onChangeCurrentLocalPaymentNumber,
        hint: USER_PAIR['localPaymentNumber'],
        placeholder: '1',
      },
      {
        value: currentMailingAddress,
        onChange: onChangeMailingAddress,
        hint: USER_PAIR['mailingAddress'],
        placeholder: '111111, Город, ул. Улица, дом 1, кв 1',
      },
      {
        value: currentEmployeesCount.toString(),
        onChange: onChangeEmployeesCount,
        hint: USER_PAIR['employeesCount'],
        placeholder: '1',
      },
    ];
  }, [
    currentPhoneNumber,
    currentAddress,
    currentCompanyName,
    currentFirstName,
    currentLastName,
    currentMiddleName,
    currentEmail,
    currentAccountNumber,
    currentInn,
    currentLocalPaymentNumber,
    currentMailingAddress,
    currentEmployeesCount,
  ]);

  return (
    <div className="user-info__container">
      {CLIENT_INPUTS.map(({hint, onChange, value, placeholder, mask}) => (
        <Input
          key={hint}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          hint={hint}
          mask={mask}
          isTextarea
          classNameHint="user-info__hint"
          className="user-info__textarea"
        />
      ))}
      {(isErrorClient || isErrorUser) && (
        <div className="text__error">
          Произошла ошибка при изменении данных. Попробуйте перезагрузить страницу или попробовать
          позже
        </div>
      )}
      <Button
        onClick={onSave}
        text="Сохранить"
        disabled={disabled}
        className="user-info__button"
        isLoading={isLoadingUser || isLoadingClient}
      />
    </div>
  );
};

export default EditableForm;
