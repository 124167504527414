import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {useChangeStatusMutation} from '@store/application/api';
import {GlobalState} from '@store/reducer';
import {selectClientId, selectStatus} from '@store/application/selectors';

interface StateProps {
  clientId: string;
  status: string;
}

interface ApplicationReasonProps {
  rejectReason: string;
}

type Props = ApplicationReasonProps & StateProps;

const ApplicationReason = ({rejectReason, clientId, status}: Props) => {
  const [rejectApplication, {isLoading, isError}] = useChangeStatusMutation();

  const [rejectMessage, setRejectMessage] = useState(rejectReason);
  const [disabledRejectButton, setDisabledRejectButton] = useState(!rejectReason.length);

  const disabled = status.startsWith('reject');

  const onChangeRejectMessage = (text: string) => setRejectMessage(text);

  const onReject = () => {
    void (async () => {
      await rejectApplication({
        status: 'fail',
        client_id: clientId,
        reject_reason: rejectMessage,
      });
    })();
  };

  useEffect(() => {
    if (rejectMessage.length > 10) {
      setDisabledRejectButton(false);
      return;
    }

    setDisabledRejectButton(true);
  }, [rejectMessage]);

  return (
    <>
      <Input
        value={rejectMessage}
        onChange={onChangeRejectMessage}
        hint="Причина отказа"
        isTextarea={true}
        disabled={disabled}
        classNameHint="user-info__hint"
        className="user-info__textarea"
      />
      <Button
        onClick={onReject}
        text="Отказать"
        isLoading={isLoading}
        className="user-info__button"
        disabled={disabledRejectButton || disabled}
      />
      {isError && (
        <div className="text__error">
          Произошла непредвиденная ошибка. Повторите позже или перезагрузите страницу
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  clientId: selectClientId(state),
  status: selectStatus(state),
});

export default connect(mapStateToProps)(ApplicationReason);
