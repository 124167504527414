import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {authApi} from '@store/auth/api';
import {State} from '@store/auth/slice';
import {logoutProfile} from '@store/auth/actions';

export const authReducer = (builder: ActionReducerMapBuilder<State>) =>
  builder
    .addCase(logoutProfile, state => {
      state.token = '';
    })
    .addMatcher(authApi.endpoints?.authorization.matchFulfilled, (state, {payload}) => {
      state.token = payload?.access_token;
    });
