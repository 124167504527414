import React from 'react';
import {connect} from 'react-redux';

import ApplicationStatus from '@components/Client/ApplicationInfo/Status';
import EditableForm from '@components/Client/ApplicationInfo/EditableForm';
import ApplicationReason from '@components/Client/ApplicationInfo/Reason';

import {GlobalState} from '@store/reducer';
import {selectApplicationStatus} from '@store/application/selectors';
import {Application} from '@store/application/slice';

import './index.css';

interface StateProps {
  applicationStatus: Pick<Application, 'rejectReason' | 'statusText'>;
}

type Props = StateProps;

const ApplicationInfo = ({applicationStatus: {rejectReason, statusText}}: Props) => {
  return (
    <>
      <div className="client__subtitle mb-3">Информация о заявке</div>
      <div className="user-info__container mb-3">
        <ApplicationStatus statusText={statusText} />
        <ApplicationReason rejectReason={rejectReason} />
        <EditableForm />
      </div>
    </>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  applicationStatus: selectApplicationStatus(state),
});

export default connect(mapStateToProps)(ApplicationInfo);
