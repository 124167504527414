import React from 'react';

import UserInfoPhotos from '@components/Client/UserInfo/Photos';
import EditableForm, {ActionButtonType} from '@components/Client/UserInfo/EditableForm';

import {Client} from '@store/client/types';

import './index.css';

interface UserInfoProps {
  client: Client;
  photos: string[];
  actionButtonType: ActionButtonType;
}

type Props = UserInfoProps;

const UserInfo = ({client, actionButtonType, photos}: Props) => {
  return (
    <>
      <div className="client__subtitle mb-3">Информация о клиенте</div>
      <div className="user-info__container">
        <EditableForm client={client} actionButtonType={actionButtonType} />
        <UserInfoPhotos photos={photos} />
      </div>
    </>
  );
};

export default UserInfo;
