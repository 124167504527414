import * as React from 'react';

import {SvgProps} from '@assets/icons/types';

import {black} from '@constants/color';

const SvgFile = (props: SvgProps) => {
  const {size, color, className} = props;

  return (
    <div className={className && className}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" {...props}>
        <path
          stroke={color || black}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 17h6m-6-4h6M9 9h1m3-6H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 4.52 5 5.08 5 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 21 7.08 21 8.2 21h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C19 19.48 19 18.92 19 17.8V9m-6-6 6 6m-6-6v4.4c0 .56 0 .84.109 1.054a1 1 0 0 0 .437.437C13.76 9 14.04 9 14.6 9H19"
        />
      </svg>
    </div>
  );
};

export default SvgFile;
