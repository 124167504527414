import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';

import {useLazyGetFileQuery} from '@store/client/apiStorage';

import './index.css';

type ParamsType = {
  bucket: string;
  fileName: string;
  userId: string;
};

const ClientFilePage = () => {
  const [getFile, {isLoading, isError}] = useLazyGetFileQuery();

  const {fileName, userId, bucket} = useParams<ParamsType>();

  useEffect(() => {
    if (!fileName || !userId || !bucket) return;

    const paramForQuery = {
      file_name: `${userId}/${fileName}`,
      bucket,
    };
    void (async () => {
      await getFile(paramForQuery).then(response => {
        window.location.replace(response?.data ?? '');

        //Если ответ будет файл а не ссылка
        // const url = window.URL.createObjectURL(
        //   new Blob([response?.data ?? ''], {type: 'image/jpeg'}),
        // );
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', fileName);
        // document.body.appendChild(link);
        // link.click();
        // link?.parentNode?.removeChild(link);

        setTimeout(() => window?.close?.(), 5000);
      });
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="container">
      <div className="get-file__process get-file__text">
        <div>ПОЛУЧЕНИЕ ФАЙЛА</div>
        {isLoading && (
          <div className="get-file__loader">
            <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
          </div>
        )}
      </div>
      {isError && (
        <div className="text__error get-file__text">Произошла ошибка в получении файла</div>
      )}
    </div>
  );
};

export default ClientFilePage;
