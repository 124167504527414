import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';

import Input from '@components/Common/Input';
import Button from '@components/Common/Button';

import {Application} from '@store/application/slice';
import {GlobalState} from '@store/reducer';
import {selectApplicationEditable} from '@store/application/selectors';
import {ApplicationFetch, useUpdateApplicationMutation} from '@store/application/api';

import {APPLICATION_PAIR} from '@constants/common';

import {MASKS, VALIDATION_TYPE} from '@utils/validations';
import {
  convertDateToString,
  TIME_FORMAT,
  ISO_FORMAT_DATE,
  joinDateAndTimeToISOString,
} from '@utils/date';

import './index.css';

type ApplicationEditable = Omit<
  Application,
  'status' | 'rejectReason' | 'statusText' | 'isLastStatus'
>;

interface EditableFormProps {
  applicationEditable: ApplicationEditable;
}

type Props = EditableFormProps;

const EditableForm = ({
  applicationEditable: {
    id,
    meetingPlace,
    meetingComment,
    bankBic,
    meetingTime,
    bankAccount,
    bankName,
    clientId,
  },
}: Props) => {
  const [updateApplication, {isLoading, isError}] = useUpdateApplicationMutation();

  const [place, setPlace] = useState(meetingPlace);
  const [date, setDate] = useState(
    meetingTime ? convertDateToString(meetingTime, ISO_FORMAT_DATE) : '',
  );
  const [time, setTime] = useState(
    meetingTime ? convertDateToString(meetingTime, TIME_FORMAT) : '00:00',
  );
  const [comment, setComment] = useState(meetingComment);
  const [account, setAccount] = useState(bankAccount);
  const [bic, setBic] = useState(bankBic);
  const [bank, setBank] = useState(bankName);

  const [disabled, setDisabled] = useState(true);

  const onChangePlace = (text: string): void => setPlace(text);
  const onChangeDate = (text: string): void => setDate(text);
  const onChangeTime = (text: string): void => setTime(text);
  const onChangeComment = (text: string): void => setComment(text);
  const onChangeAccount = (text: string): void => setAccount(text);
  const onChangeBic = (text: string): void => setBic(text);
  const onChangeBank = (text: string): void => setBank(text);

  const onSave = () => {
    const dataToUpdate: ApplicationFetch = {
      id,
      client_id: clientId,
      meeting_place: place,
      meeting_time: joinDateAndTimeToISOString(date, time),
      meeting_comment: comment,
      bank_account: account,
      bank_bic: bic,
      bank_name: bank,
    };

    void (async () => {
      await updateApplication(dataToUpdate).then(res => {
        if ('data' in res && res.data) alert('Данные успешно обновлены');
      });
    })();
  };

  const APPLICATION_INPUTS = useMemo(() => {
    return [
      {
        value: place,
        onChange: onChangePlace,
        hint: APPLICATION_PAIR['meetingPlace'],
        placeholder: '111111, Город, ул. Улица, дом 1, кв 1',
      },
      {
        value: date,
        onChange: onChangeDate,
        hint: APPLICATION_PAIR['meetingDate'],
        type: 'date',
      },
      {
        value: time,
        onChange: onChangeTime,
        hint: APPLICATION_PAIR['meetingTime'],
        type: 'time',
      },
      {
        value: account,
        onChange: onChangeAccount,
        hint: APPLICATION_PAIR['bankAccount'],
        placeholder: '00000 000 0 000000 00000',
        mask: MASKS[VALIDATION_TYPE.BANK_ACCOUNT],
      },
      {
        value: bic,
        onChange: onChangeBic,
        hint: APPLICATION_PAIR['bankBic'],
        placeholder: '000 000 000',
      },
      {
        value: bank,
        onChange: onChangeBank,
        hint: APPLICATION_PAIR['bankName'],
        placeholder: "АО 'Название Банка'",
      },
      {
        value: comment,
        onChange: onChangeComment,
        hint: APPLICATION_PAIR['meetingComment'],
        placeholder: '',
      },
    ];
  }, [account, bank, bic, comment, date, place, time]);

  useEffect(() => {
    if (
      (place === meetingPlace &&
        comment === meetingComment &&
        time === convertDateToString(meetingTime, TIME_FORMAT) &&
        date === convertDateToString(meetingTime, ISO_FORMAT_DATE) &&
        account === bankAccount &&
        bic === bankBic &&
        bank === bankName) ||
      !date
    ) {
      return setDisabled(true);
    }

    setDisabled(false);
  }, [
    account,
    bank,
    bic,
    comment,
    place,
    date,
    time,
    meetingComment,
    meetingTime,
    meetingPlace,
    bankAccount,
    bankBic,
    bankName,
  ]);

  return (
    <div className="user-info__container mb-3">
      {APPLICATION_INPUTS.map(({hint, onChange, value, placeholder, type, mask}) => (
        <Input
          key={hint}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          hint={hint}
          type={type}
          mask={mask}
          isTextarea={true}
          classNameHint="user-info__hint"
          className="user-info__textarea"
        />
      ))}
      {isError && (
        <div className="text__error">
          Произошла ошибка при изменении данных. Попробуйте перезагрузить страницу или попробовать
          позже
        </div>
      )}
      <Button
        onClick={onSave}
        text="Сохранить"
        className="user-info__button"
        disabled={disabled}
        isLoading={isLoading}
      />
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  applicationEditable: selectApplicationEditable(state),
});

export default connect(mapStateToProps)(EditableForm);
