import React from 'react';

import ClientApplication from '@components/Client';

const ApplicationPage = () => {
  return (
    <div className="container">
      <ClientApplication />
    </div>
  );
};

export default ApplicationPage;
