import React, {ChangeEvent, ForwardedRef, forwardRef, memo, useState} from 'react';
import classNames from 'classnames';
import {Form, InputGroup} from 'react-bootstrap';
import MaskedInput, {Mask} from 'react-text-mask';

import './index.css';

import {SvgEye, SvgEyeOff} from '@assets/icons';

interface InputProps {
  value?: string;
  defaultValue?: string;
  onChange?: (text: string) => void;
  placeholder?: string;
  isTextarea?: boolean;
  hint?: string;
  className?: string;
  classNameHint?: string;
  disabled?: boolean;
  type?: string;
  possibleHide?: boolean;
  mask?: Mask | ((value: string) => Mask);
}

type Props = InputProps;

const Input = forwardRef(
  (
    {
      value,
      defaultValue,
      onChange = () => {},
      hint,
      mask = false,
      placeholder,
      className,
      classNameHint,
      isTextarea = false,
      disabled = false,
      possibleHide = false,
      type = 'text',
    }: Props,
    ref?: ForwardedRef<HTMLInputElement>,
  ) => {
    const [showPassword, setShowPassword] = useState(possibleHide);

    const changeEye = () => setShowPassword(prev => !prev);

    const onChangeText = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

    if (type === 'date' || type === 'time') {
      return (
        <InputGroup className={className}>
          {hint && (
            <InputGroup.Text
              id="inputGroup-sizing-default"
              className={classNames('input__hint', classNameHint)}>
              {hint}
            </InputGroup.Text>
          )}
          <input
            type={type}
            className="shadow-none border-0 input__date"
            ref={ref}
            value={value}
            disabled={disabled}
            onChange={onChangeText}
          />
        </InputGroup>
      );
    }

    return (
      <InputGroup className={className}>
        {hint && (
          <InputGroup.Text
            id="inputGroup-sizing-default"
            className={classNames('input__hint', classNameHint)}>
            {hint}
          </InputGroup.Text>
        )}
        <MaskedInput
          mask={mask}
          type={possibleHide ? (showPassword ? 'password' : type) : type}
          className="input__input shadow-none border-0"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          placeholder={placeholder && placeholder}
          defaultValue={defaultValue}
          disabled={disabled}
          onChange={onChangeText}
          render={(ref, props) => (
            <Form.Control
              ref={ref as (instance: HTMLInputElement | null) => void}
              {...props}
              value={value}
              as={isTextarea ? 'textarea' : 'input'}
            />
          )}
        />
        {possibleHide && (
          <InputGroup.Text className="input__icon" onClick={changeEye}>
            {showPassword ? <SvgEye size={24}></SvgEye> : <SvgEyeOff size={24}></SvgEyeOff>}
          </InputGroup.Text>
        )}
      </InputGroup>
    );
  },
);

export default memo(Input);
