import React, {ChangeEvent, useState} from 'react';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';
import {connect} from 'react-redux';

import Button from '@components/Common/Button';

import {useSendFileMutation} from '@store/client/apiStorage';
import {addFiles} from '@store/client/actions';
import {GlobalState} from '@store/reducer';
import {selectClientId} from '@store/client/selectors';

import {ONE_MEGABYTE_IN_BYTES} from '@constants/common';

import './index.css';

interface StateProps {
  userId: string;
}

interface DispatchProps {
  addFiles: typeof addFiles;
}

type Props = DispatchProps & StateProps;

const AttachFile = ({addFiles, userId}: Props) => {
  const [sendFile, {isLoading}] = useSendFileMutation();

  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setMessage('');
    setDisabled(false);
    if (file) {
      setFile(file);
      if (file.size && file.size > ONE_MEGABYTE_IN_BYTES * 10) {
        setMessage('Файл не должен весить больше 10 мегабайт');
        setDisabled(true);
      }
      if (!file.size) {
        setMessage('Файл не должен быть пустым');
        setDisabled(true);
      }
    }
  };

  const handleSendFile = () => {
    if (!file) return;
    void (async () => {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('client_id', userId);
      await sendFile(formData).then(res => {
        if ('data' in res && res.data) {
          addFiles(res.data);
          alert('Файл успешно отправлен');
          clearFile();
        }
        if ('error' in res && res?.error && 'status' in res.error && res.error?.status === 422) {
          setMessage('Ошибка. Файл не имеет расширения');
        }
      });
    })();
  };

  const clearFile = () => {
    setFile(null);
    setMessage('');
    setDisabled(false);
  };

  return (
    <div className="attach-file client__subtitle mb-3">
      <div className="attach-file__container">
        <div className="attach-file__text">Отправить файл пользователю:</div>
        <div className="attach-file__input">
          <label htmlFor="input_file">Загрузить файл</label>
          <input type="file" id="input_file" onChange={handleChangeFile} />
        </div>
      </div>
      {file && (
        <>
          <div className="attach-file__container">
            <div>Выбран файл: {file?.name}</div>
            <Button
              text="Отправить"
              onClick={handleSendFile}
              isLoading={isLoading}
              disabled={disabled}
            />
            <Button text="Очистить" onClick={clearFile} />
          </div>
          {message && <div className="text__error">{message}</div>}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  userId: selectClientId(state),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addFiles,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AttachFile);
