import React from 'react';

import Client from '@components/Client/Client';

const ClientPage = () => {
  return (
    <div className="container">
      <Client />
    </div>
  );
};

export default ClientPage;
