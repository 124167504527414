import {BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from '@reduxjs/toolkit/query';

import {GlobalState} from '@store/reducer';
import {logoutProfile} from '@store/auth/actions';

import {API_URL} from '@constants/api';

import {getCookie, removeCookie} from '@utils/cookieApi';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, {getState, endpoint}) => {
    const token = (getState() as GlobalState).auth.token;

    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    headers.set('Access-Control-Allow-Headers', 'Content-Type, Authorization');
    headers.set('Access-Control-Allow-Methods', 'POST, GET, PATCH');
    headers.set('Access-Control-Max-Age', '86400');
    //Если отправялем файл и надо Content-Type: from-data, то заголовок не устанавливаем вообще, браузер сделает это автоматически
    endpoint !== 'sendFile' && headers.set('Content-Type', 'application/json');

    return headers;
  },
});

export const baseQueryWithLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const token = getCookie('token') as string | undefined;

  if (
    (result?.error &&
      'originalStatus' in result.error &&
      result.error.originalStatus === 401 &&
      token) ||
    result?.error?.status === 401
  ) {
    api.dispatch(logoutProfile());
    removeCookie('token');
    window.location.replace('/auth');
  }

  return result;
};
