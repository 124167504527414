import React from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from '@reduxjs/toolkit';

import {logoutProfile} from '@store/auth/actions';

import {removeCookie} from '@utils/cookieApi';

import './index.css';

interface DispatchProps {
  logoutProfile: typeof logoutProfile;
}

type Props = DispatchProps;

const Navigation = ({logoutProfile}: Props) => {
  const navigate = useNavigate();

  const setActive = ({isActive}: {isActive: boolean}) =>
    isActive ? 'nav__item nav__item__active' : 'nav__item';

  const onLogOut = () => {
    logoutProfile();
    removeCookie('token');
    navigate('/auth');
    window.location.reload();
  };

  return (
    <nav className="navigation">
      <div className="nav__container">
        <NavLink to="/application" className={setActive}>
          Заявка
        </NavLink>
        <NavLink to="/client" className={setActive}>
          Клиент
        </NavLink>
      </div>
      <div className="nav__container">
        <div className="nav__item" onClick={onLogOut}>
          Выйти
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logoutProfile,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
