export enum VALIDATION_TYPE {
  NUMBER = 'number',
  BANK_ACCOUNT = 'account',
  PHONE = 'phone',
  INN = 'inn',
}

export const MASKS = {
  [VALIDATION_TYPE.BANK_ACCOUNT]: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  [VALIDATION_TYPE.PHONE]: [
    '+',
    /\d/,
    ' ',
    '(',
    /\d/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
  ],
};

export const REGEX = {
  [VALIDATION_TYPE.NUMBER]: /^[0-9]*$/,
  [VALIDATION_TYPE.INN]: /^\d{10}$|^\d{12}$/,
  [VALIDATION_TYPE.BANK_ACCOUNT]: /^[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4}$/,
  [VALIDATION_TYPE.PHONE]: /^\+7?[\s\\-]\([0-9]{3}\)\s[0-9]{3}[\\-][0-9]{2}[\\-][0-9]{2}$/,
};

export const validate = (value: string, type: VALIDATION_TYPE) => {
  return REGEX[type].test(value);
};
