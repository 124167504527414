export const formatFileSize = (size: number): string => {
  //size - размер в байтах
  const units = ['B', 'KB', 'MB', 'GB'];
  if (size === 0) {
    return `0.0 ${units[0]}`;
  }

  const unitIndex = Math.floor(Math.log(size) / Math.log(1024));
  const convertedSize = size / Math.pow(1024, unitIndex);

  return `${convertedSize.toFixed(1)} ${units[unitIndex]}`;
};
