import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import AuthPage from '@pages/Auth';
import NotFoundPage from '@pages/NotFound';
import ApplicationPage from '@pages/Application';
import PrivateRoutes from '@pages/PrivateRoutes';
import ClientPage from '@pages/Client';
import ClientFilePage from '@pages/ClientFile';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route element={<PrivateRoutes />}>
          {/* Приватные роуты */}
          <Route path="/application" element={<ApplicationPage />} />
          <Route path="/client" element={<ClientPage />} />
          <Route path="/client/file/:bucket/:userId/:fileName" element={<ClientFilePage />} />
          <Route path="/not-found-page" element={<NotFoundPage />} />
          <Route path="/" element={<Navigate to="/application" replace />} />
          <Route path="*" element={<Navigate to="/not-found-page" replace />} />
        </Route>
        {/* НЕ приватные роуты */}
        <Route path="/auth" element={<AuthPage />} />
      </Routes>
    </>
  );
};

export default App;
