import {createApi} from '@reduxjs/toolkit/query/react';

import {baseQueryWithLogout} from '@store/api';

interface PostTokenRequest {
  login: string;
  password: string;
}

interface PostTokenResponse {
  access_token: string;
}

export const authApi = createApi({
  baseQuery: baseQueryWithLogout,
  reducerPath: 'authApi',
  keepUnusedDataFor: 5,
  endpoints: builder => ({
    authorization: builder.mutation<PostTokenResponse, PostTokenRequest>({
      query: data => ({
        url: 'users/login',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {useAuthorizationMutation} = authApi;
