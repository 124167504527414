import {Application, User} from '@store/application/slice';

export const ONE_DAY_IN_MILLISECONDS = 86400000;

export const ONE_MEGABYTE_IN_BYTES = 1048576;

type UserFieldNames = Omit<User, 'id'>;

export const USER_PAIR: {
  [key in keyof UserFieldNames]: string;
} = {
  phoneNumber: 'Номер телефона',
  firstName: 'Имя',
  lastName: 'Фамилия',
  middleName: 'Отчество',
  email: 'email',
  companyName: 'Наименование организации',
  accountNumber: 'Номер счета',
  address: 'Адрес регистрации',
  mailingAddress: 'Почтовый адрес',
  employeesCount: 'Количество сотрудников',
  inn: 'ИНН',
  localPaymentNumber: 'Стартовый номер П/П',
};

export const APPLICATION_PAIR: {
  [key in keyof Application | 'meetingDate']: string;
} = {
  id: 'id заявки',
  statusText: 'Статус',
  status: 'Статус',
  meetingPlace: 'Место встречи',
  meetingTime: 'Время встречи',
  meetingDate: 'Дата встречи',
  meetingComment: 'Комментарий',
  rejectReason: 'Причина отказа',
  bankName: 'Наименование банка',
  bankAccount: 'Счет клиента',
  bankBic: 'БИК банка',
  clientId: 'Id клиента',
  isLastStatus: 'Последний статус',
};
