import Cookies from 'universal-cookie';
import {CookieSetOptions} from 'universal-cookie/cjs/types';

const cookies = new Cookies();

export const setCookie = (name: string, value: any, options: CookieSetOptions | undefined): void =>
  cookies.set(name, value, options && options);

export const getCookie = (name: string): any => cookies.get(name);

export const removeCookie = (name: string): void => cookies.remove(name);
