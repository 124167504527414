import React from 'react';

import Auth from '@components/Auth';

const AuthPage = () => {
  return (
    <div className="container">
      <Auth />
    </div>
  );
};

export default AuthPage;
