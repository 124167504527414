import {createSlice} from '@reduxjs/toolkit';

import {clientReducer} from '@store/client/reducer';
import {Client, ClientFile} from '@store/client/types';

export type State = {
  info: Client;
  photos: string[];
  files: ClientFile[];
};

export const initialState: State = {
  photos: [],
  info: {
    id: '',
    phoneNumber: '',
    companyName: '',
    address: '',
    firstName: '',
    middleName: '',
    inn: '',
    lastName: '',
    email: '',
    accountNumber: '',
    mailingAddress: '',
    employeesCount: 0,
    localPaymentNumber: 1,
  },
  files: [],
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: clientReducer,
});

export default slice;
