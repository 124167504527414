import {configureStore} from '@reduxjs/toolkit';

import {reducers} from '@store/reducer';

import {authApi} from '@store/auth/api';
import {clientApi} from '@store/client/api';
import {applicationApi} from '@store/application/api';
import {clientStorageApi} from '@store/client/apiStorage';

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: true,
    }).concat(
      authApi.middleware,
      applicationApi.middleware,
      clientApi.middleware,
      clientStorageApi.middleware,
    ),
});

export default store;
