import {createSlice} from '@reduxjs/toolkit';

import {authReducer} from '@store/auth/reducer';

import {getCookie} from '@utils/cookieApi';

export type State = {
  token: string;
};

const token = getCookie('token') as string | undefined;

const initialState: State = {
  token: token ? token : '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: authReducer,
});

export default slice;
