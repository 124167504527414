import React, {useState} from 'react';

import FilesListRow from '@components/Client/Client/Files/List/Row';
import Button from '@components/Common/Button';

import {ClientFile, FileItem} from '@store/client/types';

import {sortDescending} from '@utils/array';
import {convertDateToUnix} from '@utils/date';

import './index.css';

type FilterFilesType = 'all' | 'support' | 'client';

interface FilesListProps {
  filesByDates: ClientFile[];
}

type Props = FilesListProps;

const FilesList = ({filesByDates}: Props) => {
  const [activeFilter, setActiveFilter] = useState<FilterFilesType>('all');

  const mapFiles = ({files, date}: {files: FileItem[]; date: string}) => {
    const filteredFiles = files.filter(({owner}) =>
      activeFilter === 'all' ? true : owner === activeFilter,
    );
    return <FilesListRow key={date} files={filteredFiles} date={date} />;
  };

  return (
    <div className="files">
      <div className="files__header mb-3">
        <div className="client__subtitle">Файлы:</div>
        <div className="files__filter">
          <Button
            text={'Все'}
            onClick={() => setActiveFilter('all')}
            type="outline-secondary"
            isActive={activeFilter === 'all'}
          />
          <Button
            text={'Входящие'}
            onClick={() => setActiveFilter('client')}
            type="outline-secondary"
            isActive={activeFilter === 'client'}
          />
          <Button
            text={'Исходящие'}
            onClick={() => setActiveFilter('support')}
            type="outline-secondary"
            isActive={activeFilter === 'support'}
          />
        </div>
      </div>
      <div className="files__container">
        {[...filesByDates]
          //Сортировка по дате
          .sort((a, b) => sortDescending(convertDateToUnix(a.date), convertDateToUnix(b.date)))
          .map(mapFiles)}
      </div>
    </div>
  );
};

export default FilesList;
