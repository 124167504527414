import React, {memo, ReactNode} from 'react';
import {Button as ButtonBootstrap, Spinner} from 'react-bootstrap';

type ButtonType =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-danger';

interface ButtonProps {
  onClick: () => void;
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  type?: ButtonType;
  isActive?: boolean;
}

type Props = ButtonProps;

const Button = ({
  disabled = false,
  text = '',
  onClick,
  isLoading = false,
  className = '',
  type = 'primary',
  isActive = false,
}: Props): ReactNode => {
  return (
    <ButtonBootstrap
      variant={type}
      active={isActive}
      className={className}
      disabled={isLoading ? true : disabled}
      onClick={onClick}>
      {isLoading ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : text}
    </ButtonBootstrap>
  );
};

export default memo(Button);
