import React from 'react';
import {connect} from 'react-redux';

import ClientSearch from '@components/Client/Search';
import UserInfo from '@components/Client/UserInfo';
import AttachFile from '@components/Client/Client/Files/Attach';
import FilesList from '@components/Client/Client/Files/List';

import {GlobalState} from '@store/reducer';
import {selectClient, selectPhotos, selectFiles} from '@store/client/selectors';
import {Client as ClientType, ClientFile} from '@store/client/types';

interface StateProps {
  client: ClientType;
  photos: string[];
  files: ClientFile[];
}

type Props = StateProps;

const Client = ({client: {phoneNumber, id}, photos, client, files}: Props) => {
  return (
    <div className="client">
      <div className="client__title mb-3">Получить данные по клиенту</div>
      <ClientSearch search="client" initialValue={id} />

      <div className="client__container">
        {phoneNumber && <UserInfo actionButtonType="client" client={client} photos={photos} />}
        {id && <AttachFile />}
        {files.length > 0 && <FilesList filesByDates={files} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  client: selectClient(state),
  photos: selectPhotos(state),
  files: selectFiles(state),
});

export default connect(mapStateToProps)(Client);
